@font-face {
  font-family: "myFont";
  src: url('./pages/libs/myFont.otf');
}
body {
  font-family: "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei";
}
.fr {
  display: flex;
  flex-direction: row;
}
.fc {
  display: flex;
  flex-direction: column;
}
.novelShadow {
  box-shadow: 1px 1px 6px 1px #e8e8e8;
}
.te {
  text-align: center;
}
.l1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -Webkit-box-orient: vertical;
}
.l2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -Webkit-box-orient: vertical;
}
.l3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -Webkit-box-orient: vertical;
}
.themeBgColor {
  background: rgba(103, 219, 236, 1) !important
}
.themeColor {
  color: rgba(103, 219, 236, 1) !important
}
.error-log-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.5);
  // display: flex;
  display: none;
  justify-content: center;
  align-items: center;
  .error-log-content {
    box-sizing: border-box;
    width: 74%;
    font-size: 28px;
    background: #fff;
    border-radius: 8px;
    p {
      color: #808080;
      padding: 5px 20px;
    }
    .error-log-title {
      margin-bottom: 10px;
      text-align: center;
      padding: 20px 24px 9px;
      font-size: 32px;
      border-bottom: 1px solid #ddd;
    }
    .error-log-btn {
      margin-top: 10px;
      border-top: 1px solid #ddd;
      text-align: center;
      padding: 20px;
      font-size: 34px;
      color: rgb(60, 197, 31);
    }
  }
}
// .themeBgColor {
//   background: rgba(255, 140, 4, 1) !important;
// }
// .themeColor {
//   color: rgba(103, 219, 236, 1) !important;
// }